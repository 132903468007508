.hero {
    position: relative;
  }
  .hero h3 {
    font-weight: 100;
    letter-spacing: 2px;
    padding-top: 10%;
  }
  .hero h1 {
    font-size: 60px;
    font-weight: bold;
  }
  
  .hero h1 span {
    color: #ff5100;
  }
  .hero h2 {
    font-size: 50px;
    font-weight: bold;
    color: #ff5100;
  }
  .hero h2 span {
    color: black;
  }
  .hero p {
    color: #878e99;
    font-size: 18px;
    line-height: 30px;
    margin-top: 30px;
  }
  .hero_btn {
    margin-top: 10%;
  }
  .hero_btn h4 {
    font-weight: 500;
    letter-spacing: 2px;
    color: #43474b;
    font-size: 15px;
    margin: 30px 0 30px 0;
  }
  .hero_btn button {
    margin-right: 30px;
  }
  .hero_btn i {
    font-size: 20px;
  }
  
  .hero_btn button img {
    width: 20px;
    height: 20px;
  }
  .hero .left {
    margin-right: 10%;
  }
  .hero .right {
    width: 40%;
    position: relative;
  }
  .hero .right_img {
    background: linear-gradient(145deg, #e2e8ec, #ffffff);
    box-shadow: 4px 4px 8px #cbcbcb, -4px -4px 8px #ffffff;
    border-radius: 10px;
    height: 580px;
    width: 510px;
    margin-top: 20%;
  }
  .hero .right_img img {
    position: absolute;
    top: 0;
    right: 10%;
    width: 510px;
  }
  @media (max-width: 768px) {
    .hero .right,
    .hero .left {
      width: 100%;
      margin-left: 20px;
    }
  
    .hero .f_flex {
      flex-direction: column;
      flex-direction: column-reverse;
    }
    .hero .right_img {
      display: none;
    }
    .hero .right_img img {
      display: none;
    }
  }
  @media (max-width: 425px) {
    .hero .right,
    .hero .left {
      width: 100%;
      
    }
  
    .hero .f_flex {
      flex-direction: column;
      flex-direction: column-reverse;
    }
    .hero .right_img {
     display: none;
    }
  }