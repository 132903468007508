.features .box {
    padding: 50px;
  }
  .features .box h2 {
    margin: 20px 0 20px 0;
  }
  .features .box p {
    word-spacing: 2px;
    transition: 0.5s;
  }
  .features .box i {
    font-size: 30px;
    margin-top: 20px;
    transition: 0.5s;
    opacity: 0;
  }
  .features .box:hover p {
    color: white;
  }
  .features .box:hover i {
    color: white;
    opacity: 1;
  }
  @media (max-width: 425px) {
    .grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 35px;
    }
  }  